export default {
  props: {
    formKey: String,
  },
  methods: {
    save() {
      this.formActions.save()
    },
    back() {
      if(window.vueRouteChangePrevent.hasPrevent(this.formKey) === true) {
        const confirmResult = confirm(this.$t('exit.confirm'))
        if(!confirmResult) return
      }
      window.vueRouteChangePrevent.reset()

      // 有設定列表route就回列表
      if(typeof this.formConfig.listRoute === 'function') {
        this.$router.replace(this.formConfig.listRoute())
        return
      }

      // 沒有就回上一頁面
      else {
        this.$router.go(-1)
      }
    },
    preventDefaultSave() {
      $(document).bind('keydown', function(e) {
        if(!e.ctrlKey) return
        if(e.which != 83) return
        e.preventDefault()
      })
    },
    cancelPreventDefaultSave() {
      $(document).unbind('keydown')
    },
  },
  computed: {
    saveKeymap() {
      return {
        'ctrl+s': () => this.save(),
      }
    },
    loadFailure() {
      return this.$store.getters[`form/${this.formKey}/loadFailure`]
    },
    dataAction() {
      return this.$store.getters[`form/${this.formKey}/dataAction`]
    },
    target() {
      return this.$store.getters[`form/${this.formKey}/target`]
    },
    formApi() {
      return this.$store.getters[`form/${this.formKey}/api`]
    },
    formConfig() {
      return this.$store.getters[`form/${this.formKey}/config`]
    },
    formActions() {
      return this.$store.getters[`form/${this.formKey}/actions`]
    },
    finished() {
      return this.$store.getters[`form/${this.formKey}/finished`]
    },
    hasChanged() {
      return this.$store.getters[`form/${this.formKey}/hasChanged`]
    },
    statusColor() {
      if(this.finished === false) return 'red lighten-2'
      if(this.hasChanged) return 'success lighten-1'
      return 'grey'
    },
    statusTextClass() {
      if(this.finished === false) return 'red--text text--lighten-2'
      if(this.hasChanged) return 'success--text text--lighten-1'
      return 'grey--text'
    },
    statusLabel() {
      if(!this.finished) return this.$t('form_status.unfinished')
      if(this.hasChanged) return this.$t('form_status.changed')
      return this.$t('form_status.unchanged')
    },
  },
}
